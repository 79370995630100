import React from 'react';
import { UiWrapper, NavigationWrapper, View, Text, Button } from 'coinscrap-webapp-core';

import colors from 'config/colors';

import Top from 'common/Top';
import Slide from './Components/Slide';
import routes from 'config/routes';

const OnBoarding = () => {
  const { useBackground } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.main);

  return (
    <View style={{}}>
      <Top text="Essentiel pour moi" />
      <View startSt flexGrowSt style={{ background: ' #0A2D82' }}>
        <View startSt>
          <Slide />
        </View>
        <View flexGrowSt style={{ padding: '10px 0px 30px 0px' }}>
          <Button whiteButtonSt>
            <Text font15St font700wSt fontMagenteSt upperCaseSt>
              s'inscrire
            </Text>
          </Button>
          <Button
            transparentButtonSt
            style={{ marginTop: 16 }}
            onClick={() => navigateTo(routes.login)}
          >
            <Text font15St font700wSt>
              Se connecter
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

export default OnBoarding;
