import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, TextField, NavigationWrapper } from 'coinscrap-webapp-core';
import ModalContainer from './ModalContainer';
import routes from 'config/routes';
import { UserContext } from '../../../contexts/userContext';

const LoginModal = ({ close, title }) => {
  const { loginUser } = useContext(UserContext);
  const [codSec, setCodSet] = useState('');
  const codSecRef = useRef();

  const { navigateTo } = NavigationWrapper.use();

  const handleEnregisterButtonClick = () => {
    // console.log(new Date());
    loginUser('DEMO_0001');
    close();
    navigateTo(routes.card);
  };

  useEffect(() => {
    codSecRef.current && codSecRef.current.focus();
  }, []);

  return (
    <ModalContainer title={title} close={close}>
      <TextField
        // autoFocus
        inputRef={codSecRef}
        tfNumberSt
        value={codSec}
        // type="number"
        type="password"
        style={{
          inputComponent: { textAlign: 'center' },
          containerComponent: {
            marginTop: 30,
          },
        }}
        // onChange={(e) => setCodSet(e.target.value)}
        onChange={(e) => (codSec.length < 4 ? setCodSet(e.target.value) : null)}
      />

      <Button
        style={{ border: 'none', height: 48, maxWidth: 290, marginTop: 20, width: '90%' }}
        onClick={handleEnregisterButtonClick}
      >
        Enregister
      </Button>
    </ModalContainer>
  );
};

export default LoginModal;
