import React from "react";
import { View, Image, Text } from "coinscrap-webapp-core";

import roundedCard from "assets/images/rounded-card.png";
import roundedBlock from "assets/images/rounded-block.png";
import arrow from "assets/images/arrow-right.svg";

import colors from "config/colors";

const BlockMenu = ({ onBlockClick, onLostClick }) => {
  return (
    <View
      style={{
        position: "absolute",
        zIndex: 7,
        bottom: 0,
        height: 329,
        maxWidth: 397,
        backgroundColor: "#ffffff",
        borderRadius: "12px 12px 0 0",
        justifyContent: "flex-start",
      }}
    >
      <Text
        style={{
          fontSize: 18,
          color: colors.main,
          fontWeight: 700,
          padding: "49px 20px 20px 20px",
        }}
      >
        Bloquer ou débloquer la carte
      </Text>
      <View style={{ padding: "0px 26px" }}>
        <View
          style={{
            flexDirection: "row",
            padding: "20px 0",
            borderBottom: "1px solid #D2D1D7",
          }}
          onClick={onBlockClick}
        >
          <Image src={roundedCard} style={{ width: 51, height: "auto" }} />
          <Text
            style={{
              color: "#373940",
              fontSize: 16,
              fontWeight: 700,
              marginLeft: 18.3,
            }}
          >
            Bloquer la carte
          </Text>
          <Image
            src={arrow}
            style={{ marginLeft: "auto", width: 10.69, height: 19 }}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            padding: "20px 0",
            borderBottom: "1px solid #D2D1D7",
          }}
          onClick={onLostClick}
        >
          <Image src={roundedBlock} style={{ width: 51, height: "auto" }} />
          <Text
            style={{
              color: "#373940",
              fontSize: 16,
              fontWeight: 700,
              marginLeft: 18.3,
            }}
          >
            Carte Perdue
          </Text>
          <Image
            src={arrow}
            style={{ marginLeft: "auto", width: 10.69, height: 19 }}
          />
        </View>
      </View>
    </View>
  );
};

export default BlockMenu;
