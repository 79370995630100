import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField } from 'coinscrap-webapp-core';
import ModalContainer from './ModalContainer';

const GeneralSecurityModal = ({ close, action, title }) => {
  const [codSec, setCodSet] = useState('');
  const codSecRef = useRef();

  const handleEnregisterButtonClick = async (action, close) => {
    await action();
    close();
  };

  useEffect(() => {
    codSecRef.current && codSecRef.current.focus();
  }, []);

  return (
    <ModalContainer title={title} close={close} style={{ background: '#f0f0f0', height: 250 }}>
      <TextField
        autoFocus
        inputRef={codSecRef}
        tfNumberSt
        value={codSec}
        type="password"
        style={{
          inputComponent: { textAlign: 'center' },
          containerComponent: {
            marginTop: 30,
          },
        }}
        onChange={(e) => (codSec.length < 4 ? setCodSet(e.target.value) : null)}
      />

      <Button
        style={{
          border: 'none',
          height: 48,
          maxWidth: 290,
          margin: 20,
          width: '90%',
        }}
        onClick={() => handleEnregisterButtonClick(action, close)}
      >
        Enregister
      </Button>
    </ModalContainer>
  );
};

export default GeneralSecurityModal;
