import React, { useContext } from 'react';
import {
  Text,
  View,
  Image,
  InputSwitch,
  Button,
  /* UiWrapper, */
} from 'coinscrap-webapp-core';
import ModalContainer from './ModalContainer';
/* import GeneralSecurityModal from "./GeneralSecurityModal"; */
import CreditCard from './CreditCard';

import roundedCard from 'assets/images/rounded-card.png';
import roundedBlock from 'assets/images/rounded-block.png';
import { useState } from 'react';
// import { postBlocks } from 'api/smoney';
import { postBlocks } from 'api/xpollens';

import { CardOperationsContext } from 'contexts/cardOperationsContext';

const LostModal = ({ close, blocks, cardData }) => {
  const [foreign, setForeign] = useState(blocks.foreign);
  const [onLine, setOnLine] = useState(blocks.online);
  const [opacity, setOpacity] = useState(1);
  const { cardOperations, setCardOperations } = useContext(CardOperationsContext);
  /*   const { openModal } = UiWrapper.use();

  const handleSecurityBlockModal = () => {
    setOpacity(0.5);
    openModal((close) => (
      <GeneralSecurityModal
        title="Code de sécurité"
        action={handleBlockButton}
        close={() => {
          close();
          setOpacity(1);
        }}
      />
    ));
  }; */

  const handleBlockButton = async () => {
    try {
      await postBlocks(
        cardData.user.id,
        cardData.cards?.[0].id,
        cardData.cards?.[0].blocked,
        foreign,
        onLine
      );

      //implementar useReducer porque el state es bastante complejo para manejarlo asi
      setCardOperations({
        ...cardOperations,
        cards: [
          {
            ...cardOperations.cards?.[0],
            foreignBlocked: foreign,
            internetBlocked: onLine,
          },
        ],
      });
      setOpacity(1);
    } catch {
      console.log('Error');
    }
  };

  return (
    <ModalContainer title={'Bloquer les paiements'} close={close} style={{ opacity: opacity }}>
      <CreditCard cardData={cardData} style={{ marginTop: 15 }} />

      <View style={{ padding: '20px 26px 0px 26px' }}>
        <View
          style={{
            flexDirection: 'row',
            padding: '20px 0',
            borderBottom: '1px solid #D2D1D7',
          }}
        >
          <Image src={roundedCard} style={{ width: 42, height: 'auto' }} />
          <Text
            style={{
              color: '#373940',
              fontSize: 16,
              fontWeight: 700,
              marginLeft: 18.3,
            }}
          >
            Paiements à l'étranger
          </Text>
          <View style={{ marginLeft: 'auto', width: 'auto' }}>
            <InputSwitch
              value={foreign ? true : false}
              onChange={() => setForeign((v) => (v ? 0 : 1))}
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            padding: '20px 0 0 0',
          }}
        >
          <Image src={roundedBlock} style={{ width: 42, height: 'auto' }} />
          <Text
            style={{
              color: '#373940',
              fontSize: 16,
              fontWeight: 700,
              marginLeft: 18.3,
            }}
          >
            Paiements en ligne
          </Text>
          <View style={{ marginLeft: 'auto', width: 'auto' }}>
            <InputSwitch
              value={onLine ? true : false}
              onChange={() => setOnLine((v) => (v ? 0 : 1))}
            />
          </View>
        </View>
      </View>
      <Button
        style={{
          border: 'none',
          height: 48,
          maxWidth: 290,
          marginTop: 20,
          width: '90%',
        }}
        onClick={handleBlockButton}
        // onClick={handleBlockButton}
      >
        Enregister
      </Button>
    </ModalContainer>
  );
};

export default LostModal;
