import React from 'react';
import {
  UiWrapper,
  // NavigationWrapper,
  View,
  Text,
  Button,
  TextField,
  NavigationWrapper,
} from 'coinscrap-webapp-core';

import colors from 'config/colors';
import Top from 'common/Top';
import { useState } from 'react';
import routes from 'config/routes';
import LoginModal from './components/LoginModal';

const Login = () => {
  const { useBackground, openModal } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  // const { useHeader, useFooter, openModal, useBackground } = UiWrapper.use();

  useBackground(colors.main);

  const [email, setEmail] = useState('macif@macif.com');
  const [mdp, setMdo] = useState('********');

  const handleLoginButtonClick = () => {
    // console.log(new Date());
    openModal((close) => <LoginModal title="Code de sécurité" close={close} />);
  };

  return (
    <View style={{}}>
      <Top text="Essentiel pour moi" login="Login" />
      <View startSt style={{ flexBasis: 465, background: ' #0A2D82' }}>
        <View startSt style={{ height: '50%' }}>
          <TextField
            value={email}
            id="email"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: 240, border: '1px solid #fff' }}
          />{' '}
          <TextField
            // id="email"
            value={mdp}
            type="text"
            placeholder="Mot de Passe"
            onChange={(e) => setMdo(e.target.value)}
            style={{ width: 240, border: '1px solid #fff' }}
          />
          <Text style={{ paddingTop: 30 }}>Vous avez oublié votre mot de passe?</Text>
        </View>
        <View style={{ flexGrow: 1, marginBottom: 80 }}>
          <Button whiteButtonSt onClick={() => navigateTo(routes.signup)}>
            <Text font15St font700wSt fontMagenteSt upperCaseSt>
              s'inscrire
            </Text>
          </Button>
          <Button transparentButtonSt style={{ marginTop: 16 }} onClick={handleLoginButtonClick}>
            <Text font15St font700wSt>
              Ouvrir une session
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

export default Login;
