import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import { fonts } from "../fonts";

UiWrapper.defineComponent("textCurrency", {
  styles: {
    default: {
      entireComponent: {
        lineHeight: 1.1,
        color: colors.yellow,
        fontWeight: "bold",
        fontSize: 22,
      },
      decimalComponent: {
        color: colors.yellow,
        fontWeight: "bold",
        fontSize: 20,
      },
    },
    detalleSt: {
      entireComponent: {
        lineHeight: "36px",
        color: colors.cobalt2,
        fontFamily: fonts.BankiaMedium,
        fontSize: 32,
      },
      decimalComponent: {
        lineHeight: "36px",
        color: colors.cobalt2,
        fontFamily: fonts.BankiaMedium,
        fontSize: 24,
      },
    },
    detallesmallSt: {
      entireComponent: {
        lineHeight: "24px",
        color: colors.cobalt2,
        fontFamily: fonts.SourceSansProRegular,
        fontSize: 18,
      },
      decimalComponent: {
        lineHeight: "24px",
        color: colors.cobalt2,
        fontFamily: fonts.SourceSansProRegular,
        fontSize: 12,
      },
    },
  },
});
