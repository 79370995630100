import React, { useState } from 'react';

const initialData = { userId: '' };

export const UserContext = React.createContext(initialData);

export const UserProvider = ({ children }) => {
  const [userContext, setUserContext] = useState(initialData);
  /*
  useEffect(() => {
    setUserContext({ userId: "MCF9483917" });
  }, []);*/

  return (
    <UserContext.Provider
      value={{
        ...userContext,
        loginUser: (userId) => setUserContext({ userId: userId }),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

//Los hijos que quieran acceder a este contexto: const user = useContext(UserContext);
