import colors from "../colors";
import { fonts } from "../fonts";

import commonStyles from "./common";

import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("enfatize", {
  styles: {
    default: {
      fontWeight: 800,
      color: colors.cobalt2,
    },
    font32St: {
      fontSize: 32,
      lineHeight: "50px",
    },
    bankiaMediumSt: {
      fontFamily: fonts.BankiaMedium,
    },
    font12St: {
      fontSize: 12,
      lineHeight: "24px",
    },
    normalFontWeightSt: {
      fontWeight: "normal",
    },

    ...commonStyles.styles,
  },
});
