import React, { useContext, useState } from 'react';
import { View, Text, Input, Button, Image, Tooltip } from 'coinscrap-webapp-core';
import ModalContainer from './ModalContainer';
import CreditCard from './CreditCard';
import colors from 'config/colors';
import { postLimits } from 'api/xpollens';
import { CardOperationsContext } from 'contexts/cardOperationsContext';
import info from 'assets/images/info.svg';

const LimitsModal = ({ close, limits, cardData }) => {
  //Refactorizar para quitar el prop cardData y solo obtener los datos del context en base a la id del la tarjeta en la que estamos.

  const { cardOperations, setCardOperations } = useContext(CardOperationsContext);
  const [retire, setRetire] = useState(limits.weekly);
  const [cardPayment, setCardPayment] = useState(limits.monthly);
  const [opacity, setOpacity] = useState(1);

  const handleLimitsButton = async () => {
    try {
      await postLimits(cardData.user.id, cardData.cards?.[0].id, +retire, +cardPayment);

      //implementar useReducer porque el state es bastante complejo para manejarlo asi
      setCardOperations({
        ...cardOperations,
        cards: [
          {
            ...cardOperations.cards?.[0],
            balance: cardPayment - cardOperations.cards?.[0].monthlyUsedAllowance,
            weeklyLimit: retire,
            monthlyLimit: cardPayment,
          },
        ],
      });
      setOpacity(1);
    } catch {
      console.log('Error');
    }
  };

  return (
    <ModalContainer title={'Limites'} close={close} style={{ opacity: opacity }}>
      <CreditCard cardData={cardOperations} style={{ marginTop: 15 }} />
      <View style={{ alignItems: 'flex-start', padding: '20px 50px 0px 50px' }}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: colors.main,
            lineHeight: 1,
          }}
        >
          Plafond retrait
        </Text>
        <Text
          style={{
            fontSize: 8,
            fontWeight: 500,
            color: colors.main,
          }}
        >
          sur 7 jours glissants
        </Text>
        <View style={{ position: 'relative', marginTop: 8 }}>
          <Input
            value={retire}
            onChange={(e) => setRetire(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: 48,
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
              backgroundColor: '#F5F5F599',
              borderRadius: 12,
              border: 'none',
              boxSizing: 'border-box',
              padding: '0 60px',
            }}
          />
          <Text
            style={{
              color: 'gray',
              fontSize: 24,
              position: 'absolute',
              right: 20,
              padding: '0 10px',
              borderLeft: '1px solid #bbbbbb',
              lineHeight: 1,
            }}
          >
            €
          </Text>
        </View>
      </View>
      <View style={{ alignItems: 'flex-start', padding: '15px 50px 0px 50px' }}>
        <View sparcBwSt style={{ flexDirection: 'row' }}>
          <View
            startSt
            style={{
              width: '70%',
              alignItems: 'start',
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: colors.main,
                lineHeight: 1,
              }}
            >
              Plafond paiement carte
            </Text>
            <Text
              style={{
                fontSize: 8,
                fontWeight: 500,
                color: colors.main,
              }}
            >
              sur 30 jours glissants
            </Text>
          </View>
          <View style={{ width: '20%' }}>
            <Tooltip
              withArrow
              placement="top"
              transition="zoom"
              style={{
                popperContentComponent: {
                  backgroundColor: '#e0e0e0',
                  border: '1px solid',
                  borderRadius: 7,
                  padding: 10,
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                  margin: '13px 0',
                },
                arrowComponent: {
                  color: '#f60',
                },
              }}
              content={
                <View horizontalSt>
                  <Text font14St style={{ color: colors.main, marginRight: 10 }}>
                    Dépenses cumulées pour ce mois:
                  </Text>
                  <Text textBoldSt style={{ color: colors.main }}>
                    {limits.usedMonthly} €
                  </Text>
                </View>
              }
            >
              <Image
                src={info}
                style={{
                  height: 25,
                  width: 25,
                  color: '#f60',
                  filter:
                    'invert(9%) sepia(70%) saturate(6922%) hue-rotate(227deg) brightness(74%) contrast(92%)',
                }}
              />
            </Tooltip>
          </View>
        </View>
        <View style={{ position: 'relative', marginTop: 8 }}>
          <Input
            value={cardPayment}
            onChange={(e) => setCardPayment(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: 48,
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
              backgroundColor: '#F5F5F599',
              borderRadius: 12,
              border: 'none',
              boxSizing: 'border-box',
              padding: '0 60px',
            }}
          />
          <Text
            style={{
              color: 'gray',
              fontSize: 24,
              position: 'absolute',
              right: 20,
              padding: '0 10px',
              borderLeft: '1px solid #bbbbbb',
              lineHeight: 1,
            }}
          >
            €
          </Text>
        </View>
      </View>
      <Button
        style={{ border: 'none', height: 48, maxWidth: 290, marginTop: 20, width: '90%' }}
        onClick={handleLimitsButton}
      >
        Enregister
      </Button>
    </ModalContainer>
  );
};

export default LimitsModal;
