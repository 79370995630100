import React from "react";
import { Layout, View } from "coinscrap-webapp-core";

export default ({ children, ...props }) => {
  return <Layout>{children}</Layout>;
};

/* Comentar con jesus
export default (props) => {
  return (
    <Layout>
      <Component {...props}>{props.children}</Component>
    </Layout>
  );
};
*/
