import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
// import commonStyles from "./common";
UiWrapper.defineComponent("layout", {
  styles: {
    default: {
      headerComponent: { alignItems: "start" },
      footerComponent: {
        overflow: "visible",
        height: 53,
      },
      bodyComponent: {
        justifyContent: "flex-start",
        boxSizing: "border-box",
      },
      bodyScrollerComponent: {
        maxHeight: "100%",
      },
      loadingComponent: {
        backgroundColor: colors.main + '99', // Opacity
        loaderColor: colors.white
      },
      modalComponent: {
        backgroundComponent: {
          backgroundColor: "#393939",
          opacity: "80% !important",
          backdropFilter: "blur(3px)",
        },
        contentComponent: {
          background: "none",
          boxShadow: "none",
          borderRadius: 12,
          width: "100%",
        },
      },
    },
  },
});
