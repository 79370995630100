import React from 'react';

import { View, Text, Image } from 'coinscrap-webapp-core';

import colors from 'config/colors';
import cardCircle from 'assets/images/cardCircle.png';
import visa from 'assets/images/visa.png';

const CreditCard = ({ style, cardData }) => {
  // const user = useContext(UserContext);
  // console.log('>>>>>>>>+++++++++++++++>>>>>>', cardData?.user.reel / 100);
  // console.log('>>>>>>>>+++++++++++++++>>>>>>', cardData);
  return (
    <View
      style={{
        backgroundColor: colors.main,
        height: 185,
        width: 293.88,
        borderRadius: 24,
        position: 'relative',
        boxShadow: '4px 6px 10px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'flex-start',
        ...style,
      }}
    >
      <Image
        style={{
          height: 130,
          width: 135,
          position: 'absolute',
          top: 0,
          right: 0,
          borderRadius: '0 24px 0 0',
        }}
        src={cardCircle}
      />
      <View style={{ paddingLeft: 18, paddingBottom: 18, alignItems: 'start' }}>
        <Text
          style={{
            color: 'white',
            fontSize: 10,
            width: '100%',
            boxSizing: 'border-box',
            textAlign: 'right',
            paddingRight: 13.79,
            marginTop: 16,
          }}
        >
          Debit
        </Text>
        <Text style={{ color: 'white' }}>Solde de la carte</Text>
        <Text
          sourceBlackSt
          style={{
            color: 'white',
            fontSize: 28,
            lineHeight: 1,
            marginTop: 7,
          }}
        >
          {cardData?.cards?.[0].balance
            ? cardData?.cards?.[0].balance < 0
              ? 0
              : cardData?.cards?.[0].balance > cardData.user.reel / 100
              ? cardData.user.reel / 100
              : cardData?.cards?.[0].balance //cardData?.cards?.[0].balance
            : ''}
          €
        </Text>
        <Text style={{ color: 'white', fontSize: 16, marginTop: 12 }}>
          {cardData?.user.name || 'Loading'} {cardData?.user.lastName}
          {/* Marie Le Brun */}
        </Text>
        <View style={{ marginTop: 8, alignItems: 'flex-start' }}>
          <Text style={{ color: 'white', fontSize: 16 }}>
            {cardData?.cards?.[0].number.match(/.{1,4}/g).join(' ') || 'Loading'}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginTop: 4,
            }}
          >
            <Text style={{ color: 'white', fontSize: 10, marginRight: 24 }}>CVV: 123</Text>
            <Text style={{ color: 'white', fontSize: 10 }}>
              EXPIRE AFIN: {cardData?.cards?.[0].expiration.split('-').join('/') || 'Loading'}
            </Text>
          </View>
        </View>
      </View>
      <Image
        style={{
          height: 21,
          width: 64,
          position: 'absolute',
          bottom: 17.53,
          right: 14.3,
        }}
        src={visa}
      />
    </View>
  );
};

export default CreditCard;
