import React from "react";
import { View } from "coinscrap-webapp-core";

import status from "assets/images/status-valid.svg";
import card2 from "assets/images/card2.svg";
import statement from "assets/images/statement.svg";
import category from "assets/images/category.svg";
import note from "assets/images/note.svg";

import OperationDataItem from "./OperationDataItem";
import colors from "config/colors";

const OperationData = ({ data }) => {
  console.log(data);
  const statusOp = ["En attente", "Réalisé", "En attente", "Echec"];
  return (
    <View
      style={{
        width: "95%",
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 17,
        padding: "0 35px",
        marginTop: 31,
        flexShrink: 1,
        overflow: "auto",
        marginBottom: 20,
        justifyContent: "flex-start",
      }}
    >
      {/* value={data?.status < 3 ? "Completed" : "Failed"} */}
      <OperationDataItem
        value={statusOp[data?.status]}
        icon={status}
        color={
          [colors.black, colors.green, colors.red, colors.red][data?.status]
        }
      >
        Status
      </OperationDataItem>
      <OperationDataItem value={data.carte} icon={card2}>
        Carte
      </OperationDataItem>
      <OperationDataItem value={data.statement} icon={statement}>
        Relevé
      </OperationDataItem>
      <OperationDataItem value={data.category} icon={category}>
        Catégorie
      </OperationDataItem>
      <OperationDataItem value={data.note} icon={note} last>
        Note
      </OperationDataItem>
    </View>
  );
};

export default OperationData;
