// import colors from '../colors';
// import fonts from '../fonts';

export default {
  styles: {
    paddingBottomSt: {
      paddingBottom: 15,
    },
    marginBottomSt: {
      marginBottom: 15,
    },
  },
};
