/*
Archivo de configuración donde se asocia una acción [key] con el nombre [value].
Los valores que asignemos simplemente son nombres cortos de las pantallas 
para que la app las use internamente. Las navegaciones no se realizan en base a 
paths completos si no a nombres cortos
*/

const routes = {
  onBoarding: "onboarding",
  login: "login",
  signup: "signup",
  operation: "operation",
  card: "card",

  // // Metas
  // createName: "createname",
  // createAmount: "createamount",
  // createTimeLength: "createtimelength",
  // selectImage: "selectimage",
  // finishedMeta: "finishedmeta",
  // applyRuleSelectAccount: "applyruleselectaccount",
  // applyRuleConfigure: "applyruleconfigure",

  // // Reglas

  // periodicAccount: "periodicaccount",
  // periodicDate: "periodicdate",
  // periodicFrequency: "periodicfrequency",
  // periodicAmount: "periodicamount",
  // roundingCards: "roundingcards",
  // roundingAmount: "roundingamount",
  // noGastasAccount: "nogastasaccount",
  // noGastasType: "nogastastype",
  // noGastasPercentage: "nogastaspercentage",
  // noGastasAmount: "nogastasamount",
  // maxSalaryAccount: "maxsalaryaccount",
  // maxSalaryAmount: "maxsalaryamount",
  // nominaPercentage: "nominapercentage",

  // // Detalle Metas
  // reglaCreada: "reglacreada",
  // aplicarReglas: "aplicarreglas",
  // lista: "lista",
  // listaPRE: "listapre",
  // listaPOST: "listapost",
  // listaUNDER: "listaunder",
  // listaCOMPLETE: "listacomplete",
  // detalleEvolucion: "detalleevolucion",
  // detalleEvolucionMax: "detalleevolucionmax",
  // detalleAcciones: "detalleacciones",
  // detalleReglas: "detallereglas",

  // // Traspaso Metas
  // traspasoAcciones: "traspasoacciones",
  // traspasoDonde: "traspasodonde",
  // traspasoAccount: "traspasoaccount",
  // traspasoAmount: "traspasoamount",
  // traspasoMeta: "traspasometa",

  // // Aportar a Metas
  // aportarMetas1: "aportarmetas1",
  // aportarMetas2: "aportarmetas2",
  // aportarMetas3: "aportarmetas3",
  // aportarMetas4: "aportarmetas4",
  // aportarMetas5: "aportarmetas5",
};

export default routes;
