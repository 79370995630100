import React from 'react';
import {
  UiWrapper,
  // NavigationWrapper,
  View,
  Text,
  Button,
  TextField,
  NavigationWrapper,
} from 'coinscrap-webapp-core';

import colors from 'config/colors';
import Top from 'common/Top';
import { useState } from 'react';
import routes from 'config/routes';

const Signup = () => {
  const { useBackground } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.main);

  const [nom, setNom] = useState();
  const [nomFamille, setnomFamille] = useState();
  const [email, setEmail] = useState();
  const [mdp, setMdo] = useState();

  return (
    <View style={{ maxWidth: 840 }}>
      <Top text="Essentiel pour moi" login="Sign up" />
      <View startSt style={{ flexGrow: 1, background: ' #0A2D82' }}>
        <View startSt>
          <TextField
            id="email"
            type="text"
            placeholder="Nom"
            onChange={(e) => setNom(e.target.value)}
            style={{ width: 240, border: '1px solid #fff' }}
          />
          <TextField
            id="email"
            type="text"
            placeholder="Nom de famille"
            onChange={(e) => setnomFamille(e.target.value)}
            style={{ width: 240, border: '1px solid #fff' }}
          />
          <TextField
            id="email"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: 240, border: '1px solid #fff' }}
          />
          <TextField
            // id="email"
            type="text"
            placeholder="Mot de Passe"
            onChange={(e) => setMdo(e.target.value)}
            style={{ width: 240, border: '1px solid #fff' }}
          />
          <Text font12St textCenterSt style={{ paddingTop: 20, width: '76%' }}>
            En vous inscrivant, vous acceptez les conditions de la MACIF et les conditions
            d'utilisation , la politique de confidentialité et les conditions mobiles.
          </Text>
        </View>
        <View style={{ flexGrow: 1, padding: '10px 0px 30px 0px' }}>
          <Button whiteButtonSt>
            <Text font15St font700wSt fontMagenteSt upperCaseSt>
              s'inscrire
            </Text>
          </Button>
          <Button
            transparentButtonSt
            style={{ marginTop: 16 }}
            onClick={() => navigateTo(routes.login)}
          >
            <Text font15St font700wSt>
              Ouvrir une session
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

export default Signup;
