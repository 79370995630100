import React from "react";
import { View, Text, Input } from "coinscrap-webapp-core";
import ModalContainer from "./ModalContainer";
import CreditCard from "./CreditCard";
import colors from "config/colors";

const PinModal = ({ close, cardData }) => {
  return (
    <ModalContainer title={"Code PIN"} close={close}>
      <CreditCard cardData={cardData} style={{ marginTop: 15 }} />
      <Text
        style={{
          fontSize: 15,
          fontWeight: 500,
          color: "#979797",
          marginTop: 45,
        }}
      >
        Le code secret
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
          width: '80%',
          // border: '1px solid'
        }}
      >
        <Input
          style={{
            fontSize: 30,
            fontWeight: 700,
            color: colors.main,
            textAlign: "center",
            verticalAlign: "bottom",
            width: 54,
            height: 40,
            border: "none",
            borderBottom: `6px solid ${colors.main}`,
          }}
          value="2"
        />
        <Input
          style={{
            fontSize: 30,
            fontWeight: 700,
            color: colors.main,
            textAlign: "center",
            verticalAlign: "bottom",
            width: 54,
            height: 40,
            border: "none",
            borderBottom: `6px solid ${colors.main}`,
          }}
          value="3"
        />
        <Input
          style={{
            fontSize: 30,
            fontWeight: 700,
            color: colors.main,
            textAlign: "center",
            verticalAlign: "bottom",
            width: 54,
            height: 40,
            border: "none",
            borderBottom: `6px solid ${colors.main}`,
          }}
          value="5"
        />
        <Input
          style={{
            fontSize: 30,
            fontWeight: 700,
            color: colors.main,
            textAlign: "center",
            verticalAlign: "bottom",
            width: 54,
            height: 40,
            border: "none",
            borderBottom: `6px solid ${colors.main}`,
          }}
          value="7"
        />
      </View>
    </ModalContainer>
  );
};

export default PinModal;
