import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import { fonts } from "../fonts";

import commonStyles from "./common";

UiWrapper.defineComponent("text", {
  styles: {
    default: {
      color: colors.white,
      fontSize: 13,
      fontFamily: fonts.SourceSansProRegular,
    },

    h1St: {
      width: "100%",
      marginBottom: 10,
      color: colors.white,
      textTransform: "uppercase",
      fontSize: 24,
      textAlign: "center",
      fontFamily: fonts.regular,
      lineHeight: 1.25,
      fontWeight: 800,
    },

    h2St: {
      //width: "100%",
      marginBottom: 10,
      color: colors.white,
      // textTransform: "uppercase",
      fontSize: 20,
      textAlign: "center",
      fontFamily: fonts.bold,
      fontWeight: 400,
      lineHeight: 1.25,
    },

    h2nbSt: {
      width: "100%",
      color: colors.white,
      // textTransform: "uppercase",
      fontSize: 20,
      textAlign: "center",
      // fontFamily: fonts.bold,
      // fontWeight: 400,
      lineHeight: "2rem",
    },

    h3St: {
      // width: "100%",
      color: colors.white,
      fontSize: 16,
      textAlign: "center",
      fontFamily: fonts.regular,
      fontWeight: 400,
      // textTransform: "uppercase",
    },

    h3_1St: {
      width: "100%",
      color: colors.white,
      fontSize: 14,
      textAlign: "center",
      fontFamily: fonts.regular,
      fontWeight: 400,
      // textTransform: "uppercase",
    },

    h4St: {
      color: colors.white,
      fontSize: 12,
      width: "100%",
      //marginBottom: 8,
      textAlign: "center",
      fontFamily: fonts.regular,
    },

    h5St: {
      color: "white",
      fontWeight: 600,
      fontSize: 10,
      fontFamily: fonts.regular,
      width: "100%",
      textAlign: "center",
    },

    underLineSt: {
      textDecoration: "underline",
    },

    upperCaseSt: {
      textTransform: "uppercase",
    },

    textBoldSt: {
      fontWeight: "bold",
    },

    textBorderSt: {
      borderBottomWidth: 3,
      borderBottomStyle: "solid",
      borderBottomColor: colors.grey3,
      width: "100%",
      paddingBottom: 10,
    },

    boldSt: {
      fontWeight: "bold",
    },

    noBoldSt: {
      fontWeight: "none",
      fontFamily: "Regular",
    },

    textNoBoldSt: {
      fontWeight: "none",
      fontFamily: "Regular",
    },
    opacity50St: {
      opacity: "50%",
    },

    noMarginSt: {
      margin: 0,
    },

    yellowSt: {
      color: colors.yellow,
    },

    greySt: {
      color: colors.grey,
    },
    grey1St: {
      color: colors.grey1,
    },
    grey4St: {
      color: colors.grey4,
    },
    grey7St: {
      color: colors.grey7,
    },
    grey8St: {
      color: colors.grey8,
    },
    grey10St: {
      color: colors.grey10,
    },

    green5St: {
      color: colors.green5,
    },
    green1St: {
      color: colors.green1,
    },
    blue2St: {
      color: colors.blue2,
    },

    alignSelfStartSt: {
      alignSelf: "flex-start",
    },
    alignSelfEndSt: {
      alignSelf: "flex-end",
    },

    borderSt: {
      border: "1px dotted #333",
    },

    // textSt: {
    //   color: colors.grey,
    //   fontWeight: 'bold',
    //   fontSize: 18,
    //   paddingBottom: 0,
    // },
    // textHeaderSt: {
    //   color: colors.grey,
    //   fontWeight: 'bold',
    //   fontSize: 16,
    //   textAlign: 'center',
    // },
    // textBoldSt: {
    //   color: colors.grey,
    //   fontWeight: 'bold',
    //   fontSize: 18,
    //   paddingBottom: 0,
    //   fontFamily: FontNames.bold,
    // },
    labelSt: {
      color: colors.grey,
      fontSize: 16,
      //fontWeight: 'bold',
      paddingBottom: 0,
    },

    // Para datos, en azul
    // label2St: {
    //   color: colors.violet,
    //   fontSize: 16,
    //   paddingBottom: 0,

    //   //fontWeight: 'bold',
    //   //marginBottom: 8,
    // },

    // Para datos, en gris claro
    // smallLabelSt: {
    //   color: colors.grey,
    //   fontSize: 12,
    //   fontWeight: 'bold',
    //   paddingBottom: 0,
    //   //marginBottom: 8,
    // },

    // // Para datos, en azul
    // smallLabelBlueSt: {
    //   color: colors.violet,
    //   fontSize: 12,
    //   fontWeight: 'bold',
    //   paddingBottom: 0,

    //   //marginBottom: 8,
    // },

    // Caja redondeada violeta
    // tagSt: {
    //   backgroundColor: colors.violet,
    //   borderRadius: 15,
    //   color: colors.white,
    //   fontSize: 16,
    //   padding: 10,
    //   //fontWeight: 'bold',
    //   //marginBottom: 8,
    // },

    textCenterSt: {
      textAlign: "center",
    },

    centerSt: {
      textAlign: "center",
    },

    leftSt: {
      textAlign: "left",
    },

    noLineHeightSt: {
      lineHeight: "100%",
    },
    headlineSt: {
      fontFamily: fonts.SourceSansProSemiBold,
      fontSize: 24,
      lineHeight: "28px",
      // width: "91.2%",
      width: "95%",
    },

    headlineFullSt: {
      fontFamily: fonts.SourceSansProSemiBold,
      fontSize: 24,
      lineHeight: "28px",
      // width: "91.2%",
    },

    footerTextSt: {
      fontFamily: fonts.SourceSansProSemiBold,
      fontSize: 15,
      lineHeight: "18px",
      color: colors.green1,
      marginRight: "7.7%",
      marginBottom: "4.6%",
    },
    footerButtonTextSt: {
      fontSize: 16,
      lineHeight: "17px",
      fontFamily: fonts.SourceSansProSemiBold,
      color: colors.white,
    },
    errorSt: {
      fontSize: 12,
      lineHeight: "16px",
      // fontFamily: fonts.SourceSansProSemiBold,
      fontFamily: fonts.SourceSansProRegular,
      color: colors.error,
      margin: "12px 0",
    },
    font10St: {
      fontSize: 10,
      lineHeight: "16px",
    },
    font12St: {
      fontSize: 12,
      lineHeight: "16px",
    },
    font14St: {
      fontSize: 14,
      lineHeight: "16px",
    },
    font15St: {
      fontSize: 15,
      lineHeight: "18px",
    },
    font16St: {
      fontSize: 16,
      lineHeight: "21px",
    },
    font18St: {
      fontSize: 18,
      lineHeight: "24px",
    },
    font20St: {
      fontSize: 20,
      lineHeight: "24px",
    },
    font24St: {
      fontSize: 24,
      lineHeight: "24px",
    },
    font32St: {
      fontSize: 32,
      lineHeight: "50px",
    },
    font48St: {
      fontSize: 48,
      lineHeight: "50px",
    },

    font400wSt: {
      fontWeight: 400,
    },

    font700wSt: {
      fontWeight: 700,
    },

    fontWhiteSt: {
      color: colors.white,
    },

    fontMagenteSt: {
      color: colors.magenta,
    },

    sourceBoldSt: {
      fontFamily: fonts.SourceSansProSemiBold,
    },
    sourceItalicSt: {
      fontFamily: fonts.SourceSansProItalic,
    },
    sourceBlackSt: {
      fontFamily: fonts.SourceSansProBlack,
    },

    absoluteSt: {
      position: "absolute",
    },

    roundedBlueSt: {},
    noTextTransformSt: {},
    fillAllFooterSt: {},
    fillAllFooterDisabledSt: {},
    h3BtnSt: {},
    footerBtnBlueSt: {},
    bordeSt: {},
    yellowBorderSt: {},
    decididoSt: {},
    conservadorSt: {},
    saveChangesSt: {},
    parcialSt: {},
    totalSt: {},

    ...commonStyles.styles,
  },
});
