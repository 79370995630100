import { UiWrapper } from "coinscrap-webapp-core";
import commonStyles from "./common";
import colors from "../colors";
// import { fonts } from "../fonts";

UiWrapper.defineComponent("textField", {
  styles: {
    default: {
      inputComponent: {
        color: colors.white,
        padding: 14,
        borderBottom: "1px solid #ccc ",
        width: 290,
        backgroundColor: colors.blue,
      },

      containerComponent: {},
      inputContainerComponent: {},
    },

    tfNumberSt: {
      inputComponent: {
        padding: 14,
        color: colors.white,
        borderRadius: 12,
        fontSize: 24,
        backgroundColor: colors.blue1,
      },
      containerComponent: {
        width: "82%",
      },
    },

    onBlurWithTextSt: {
      labelComponent: {
        display: "none",
      },
      focusedMode: {
        labelComponent: {
          display: "none",
        },
      },
    },
    ...commonStyles.styles,
  },
});
