import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";

UiWrapper.defineComponent("modal", {
  styles: {
    default: {
      backgroundComponent: {
        backgroundColor: "rgba(128,128,128,0.7)",
      },
      contentComponent: {
        background: "none",
        boxShadow: "none",
        overflow: "auto",
        flexShrink: 1,
      },
    },
  },
});
