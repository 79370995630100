import React from "react";
import { View, Text, Image } from "coinscrap-webapp-core";
import colors from "config/colors";

import complete from "assets/images/complete.svg";
import failure from "assets/images/failure.svg";
import waiting from "assets/images/waiting.svg";

const OperationSummary = ({ data }) => {
  const imgStatus = [
    waiting,
    complete,
    waiting,
    failure,
    waiting,
    waiting,
    waiting,
    waiting,
    waiting,
  ];
  return (
    <View
      style={{
        flexDirection: "row",
        padding: "20px 30px",
      }}
    >
      <View
        style={{
          width: 72,
          height: 72,
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.08)",
          borderRadius: 15,
          position: "relative",
        }}
      >
        <Image
          src={data.icon}
          style={{ width: 45, height: "auto", color: colors.black1 }}
        />
        {/* {data.status} */}
        <Image
          src={imgStatus[data.status]}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: 20,
            height: "auto",
            filter: !data.status
              ? "invert(9%) sepia(70%) saturate(6922%) hue-rotate(227deg) brightness(74%) contrast(92%)"
              : "",
          }}
        />
      </View>
      <View
        style={{
          width: "auto",
          alignItems: "flex-start",
          marginLeft: 26,
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: 600, color: colors.black1 }}>
          {data.name}
        </Text>
        <Text style={{ fontSize: 15, color: "#8e8e8e" }}>
          {data?.date &&
            new Date(data.date).toLocaleDateString("fr-FR", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
        </Text>
      </View>
      <View
        style={{
          width: "auto",
          marginLeft: "auto",
          flexDirection: "row",
        }}
      >
        <Text style={{ fontWeight: "700", fontSize: 24, color: colors.main }}>
          {data.amount}
          {data.currency}
        </Text>
      </View>
    </View>
  );
};

export default OperationSummary;
