import React from "react";
import { View, Text, Image } from "coinscrap-webapp-core";
import logoMacif from "assets/images/logo_macif.png";
import g5 from "assets/images/g5.png";

export default ({ text, login, style }) => {
  return (
    <View
      style={{
        backgroundImage: "url(" + g5 + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        flexShrink: 1,
        overflow: "hidden",
        flexBasis: 375,
        position: "relative",
        ...style,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: "100%",
          flexShrink: 1,
          background:
            "linear-gradient(180deg, #0A2D82 0%, rgba(10, 45, 130, 0.74) 100%)",
          // opacity: 0.9,
          transform: "rotate(-180deg)",
        }}
      />
      <View style={{ zIndex: 999, height: "100%" }}>
        <Text font18St style={{ marginTop: 24, flexGrow: 1 }}>
          {login ? login : ""}
        </Text>
        <View>
          <Image src={logoMacif} width={206} height={58} />
          <Text font24St font400wSt fontWhiteSt style={{ lineHeight: "29px" }}>
            {text}
          </Text>
        </View>
        <View style={{ flexGrow: 1 }} />
      </View>
    </View>
  );
};
