import React from "react";
import { View, Image, Text } from "coinscrap-webapp-core";
import arrowLeft from "assets/images/arrow-square-left.svg";
import colors from "config/colors";

const ModalContainer = ({ children, title, close, style }) => {
  return (
    <View>
      <View
        style={{
          zIndex: 7,
          height: 555,
          width: "100%",
          maxWidth: 377,
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          justifyContent: "flex-start",
          ...style,
        }}
      >
        <View style={{ flexDirection: "row", padding: "18px 20px 10px 20px" }}>
          <Image
            style={{
              height: 33,
              width: 33,
              marginRight: "auto",
            }}
            src={arrowLeft}
            onClick={close}
          />
          <Text
            style={{
              color: colors.main,
              fontSize: 20,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {title}
          </Text>
          <View
            style={{
              height: 33,
              width: 33,
              marginLeft: "auto",
            }}
          />
        </View>
        {children}
      </View>
    </View>
  );
};

export default ModalContainer;
