import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";

UiWrapper.defineComponent("slider", {
  styles: {
    default: {
      dotComponent: {
        backgroundColor: "rgba(0,0,0,0)",
        border: "1px solid #fff",
        margin: "0 7px",
      },
      dotActiveComponent: {
        backgroundColor: colors.white,
        border: "1px solid #fff",
      },
      dotsContainerComponent: {
        marginBottom: "2rem",
        width: "100%",
      },
    },
  },
});
