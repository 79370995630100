import React from "react";
import { View, Text, Image } from "coinscrap-webapp-core";
import arrow from "assets/images/arrow-right.svg";
import colors from "config/colors";

import card from "assets/images/card.svg";
import complete from "assets/images/complete.svg";
import failure from "assets/images/failure.svg";
import waiting from "assets/images/waiting.svg";

const OperationItem = ({ data, onClick, style }) => {
  const imgStatus = [
    waiting,
    complete,
    waiting,
    failure,
    waiting,
    waiting,
    waiting,
    waiting,
    waiting,
  ];

  return (
    <View
      style={{
        flexDirection: "row",
        padding: "25px 0",
        borderBottom: "1px solid #D2D1D7",
        ...style,
      }}
      onClick={onClick}
    >
      <View
        style={{
          width: 55,
          height: 55,
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.08)",
          borderRadius: 15,
          position: "relative",
        }}
      >
        <Image src={card} style={{ width: 30, height: "auto" }} />
        <Image
          src={imgStatus[data.status]}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: 20,
            height: "auto",
            filter: !data.status
              ? "invert(9%) sepia(70%) saturate(6922%) hue-rotate(227deg) brightness(74%) contrast(92%)"
              : "",
          }}
        />
      </View>
      <View
        style={{
          width: "auto",
          alignItems: "flex-start",
          marginLeft: 26,
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: 600, color: colors.black1 }}>
          {/* Operation  */}
          Paiement
        </Text>
        <Text style={{ fontSize: 15, color: "#8e8e8e" }}>
          {new Date(data.date).toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </Text>
      </View>
      <View
        style={{
          width: "auto",
          marginLeft: "auto",
          flexDirection: "row",
        }}
      >
        <Text style={{ fontWeight: "700", fontSize: 18, color: colors.black1 }}>
          {data.amount} €
        </Text>
        <Image
          src={arrow}
          style={{
            height: 19,
            width: "auto",
            marginLeft: 31,
            color: colors.black1,
          }}
        />
      </View>
    </View>
  );
};

export default OperationItem;
