import React, { useContext, useState } from 'react';
import { Text, Button, View /* UiWrapper */ } from 'coinscrap-webapp-core';
import ModalContainer from './ModalContainer';
import CreditCard from './CreditCard';
import { CardOperationsContext } from 'contexts/cardOperationsContext';
/* import GeneralSecurityModal from "./GeneralSecurityModal"; */
// import { postBlocks } from 'api/smoney';
import { postBlocks } from 'api/xpollens';
import { useRef } from 'react';

const BlockModal = ({ close, cardData }) => {
  const [opacity, setOpacity] = useState(1);
  const [blocked, setBlocked] = useState(cardData?.cards?.[0].blocked);
  const { cardOperations, setCardOperations } = useContext(CardOperationsContext);

  const blockRef = useRef(cardData?.cards?.[0].blocked);

  /*   const { openModal } = UiWrapper.use();

  const handleSecurityBlockModal = () => {
    setOpacity(0.5);
    openModal((close) => (
      <GeneralSecurityModal
        title="Code de sécurité"
        action={handleBlockButton}
        close={close}
      />
    ));
  }; */

  const handleBlockButton = async () => {
    setBlocked((blocked) => (blocked ? 0 : 1));
    blockRef.current = blockRef.current ? 0 : 1;
    try {
      await postBlocks(
        cardData.user.id,
        cardData.cards?.[0].id,
        blockRef.current,
        cardData.cards?.[0].foreignBlocked,
        cardData.cards?.[0].internetBlocked
      );

      //implementar useReducer porque el state es bastante complejo para manejarlo asi
      setCardOperations({
        ...cardOperations,
        cards: [
          {
            ...cardOperations.cards?.[0],
            blocked: blockRef.current,
          },
        ],
      });
      setOpacity(1);
    } catch {
      console.log('ERROR');
    }
  };

  return (
    <ModalContainer title={'Bloquer votre carte'} close={close} style={{ opacity: opacity }}>
      <CreditCard cardData={cardData} style={{ marginTop: 15 }} />
      <View style={{ padding: '0 40px 20px 40px' }}>
        <Text
          style={{
            fontSize: 15,
            fontWeight: 500,
            color: '#979797',
            marginTop: 45,
            textAlign: 'center',
          }}
        >
          Etes-vous sûr de vouloir {blocked ? 'débloquer' : 'bloquer'} votre carte ?
        </Text>
        <Button
          style={{ border: 'none', height: 48, maxWidth: 290, marginTop: 20, width: '90%' }}
          onClick={handleBlockButton}
        >
          {blocked ? 'Débloquer' : 'Bloquer'}
        </Button>
      </View>
    </ModalContainer>
  );
};

export default BlockModal;
