//Onboarding Data
export const slider_data = [
  {
    titulo: "Simple et rapide",
    subtitulo:
      "S’assurer à la Macif, c’est choisir un assureur qui peut répondre à l’ensemble de vos besoins, à chaque étape de votre vie",
  },
  {
    titulo: "Des services élargis",
    subtitulo:
      "S’assurer à la Macif, c’est bénéficier de nombreuses solutions qui simplifient vos projets et les principaux événements de votre vie",
  },
  {
    titulo: "Qualité d'écoute",
    subtitulo:
      "S’assurer à la Macif, c’est choisir une qualité d’accueil, d’écoute et de proximité",
  },
  {
    titulo: "Des valeurs mutualistes",
    subtitulo:
      "S’assurer à la Macif, c’est choisir un assureur fidèle à ses valeurs mutualistes : solidarité, équité et responsabilité",
  },
];
