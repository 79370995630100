import React from "react";

import { View, Text, Image } from "coinscrap-webapp-core";

import colors from "config/colors";

import hide from "assets/images/hide.svg";
import slider from "assets/images/slider.svg";
import settings from "assets/images/settings.svg";
import lock from "assets/images/lock.svg";

const ButtonList = ({
  onInfoClick,
  onLimitsClick,
  onBlockClick,
  onSettingsClick,
}) => {
  const buttonStyle = {
    height: 51.43,
    width: 51.43,
    backgroundColor: colors.main,
    borderRadius: "50%",
  };

  const buttonTagStyle = {
    color: colors.main,
    fontWeight: 600,
    fontSize: 10,
    marginTop: 2,
  };
  return (
    <View
      style={{
        flexDirection: "row",
        marginTop: 23.29,
        justifyContent: "space-between",
        width: 250,
      }}
    >
      <View style={{ width: "auto" }} onClick={onInfoClick}>
        <View style={buttonStyle}>
          <Image style={{ width: 20, height: 18 }} src={hide} />
        </View>
        <Text style={buttonTagStyle}>Info</Text>
      </View>
      <View style={{ width: "auto" }} onClick={onLimitsClick}>
        <View style={buttonStyle}>
          <Image style={{ width: 20, height: 18 }} src={slider} />
        </View>
        <Text style={buttonTagStyle}>Limites</Text>
      </View>
      <View style={{ width: "auto" }} onClick={onSettingsClick}>
        <View style={buttonStyle}>
          <Image style={{ width: 25, height: 25 }} src={settings} />
        </View>
        <Text style={buttonTagStyle}>Paramètres</Text>
      </View>
      <View style={{ width: "auto" }} onClick={onBlockClick}>
        <View style={buttonStyle}>
          <Image style={{ width: 24, height: 29 }} src={lock} />
        </View>
        <Text style={buttonTagStyle}>Bloquer</Text>
      </View>
    </View>
  );
};

export default ButtonList;
