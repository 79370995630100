import React from 'react';
import { View, Text } from 'coinscrap-webapp-core';

export default function Header({
  leftIcon,
  rightIcon,
  title,
  subTitle,
  style,
  progress,
  headerDimension,
  customRightIcon,
  ...props
}) {
  return (
    <View style={{ ...style, maxWidth: 840 }}>
      <View headerDimensionSt style={{ ...headerDimension }}>
        <View horizontalSt separateSt>
          <View widthAutoSt>{leftIcon && leftIcon}</View>
          <Text
            style={{
              fontSize: 18,
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: '700',
            }}
          >
            {title}
          </Text>
          <View widthAutoSt>{rightIcon && rightIcon}</View>
        </View>
        {subTitle && (
          <View horizontalSt separateSt style={{ marginTop: -3 }}>
            <View widthAutoSt horizontalSt>
              <Text style={{ fontSize: 15, marginLeft: leftIcon ? 44 : 0 }}>{subTitle}</Text>
            </View>
          </View>
        )}
      </View>
      {progress && (
        <View progressBarCabeceraSt>
          <View progressBarCabeceraActivoSt style={{ width: progress }} />
        </View>
      )}
    </View>
  );
}
