const colors = {
  main: "#0A2D82",

  // black: "rgb(0, 0, 0)",
  white: "rgb(255, 255, 255)",
  blue: "rgba(10, 45, 130, 1)",

  green: "rgb(39, 174, 96, 1)",
  red: "rgb(235, 87, 87, 1)",
  black: "rgb(51, 51, 51, 1)",

  magenta: "rgba(230,50,90,1)",

  black1: "#1C1939",

  green1: "rgb(100, 160, 0)",
  green2: "rgb(100, 164, 0)",
  green3: "rgb(102, 164, 0)",
  green4: "rgb(185, 200, 0)",
  green5: "rgb(157,184,39)",
  green6: "rgb(255,205,0)",

  cobalt1: "rgb(62, 43, 28)",
  cobalt2: "rgb(53, 38, 26)",
  cobalt2s: "rgb(53, 38, 26, 0.05)",
  cobalt3: "rgb(63, 67, 74)",

  grey1: "rgb(120, 110, 103)",
  grey2: "rgb(185, 200, 0)",
  grey3: "rgb(225, 223, 221)",
  grey4: "rgb(245, 244, 243)",
  grey5: "rgb(176, 166, 161)",
  grey6: "rgb(210, 213, 219, 0.9)",
  grey7: "rgb(154, 146, 140)",
  grey8: "rgb(185, 176, 171)",
  grey9: "rgb(204, 201, 198)",
  grey10: "rgb(165, 156, 151)",
  grey11: "rgb(235, 234, 233)",

  blue1: "rgb(138, 205, 222)",

  orange1: "rgb(255, 158, 27)",

  yellow1: "rgb(255, 205, 0)",

  white2: "rgba(255, 255, 255, 0.9)",

  yellow: "rgb(255,240,178)",
  brown: "rgba(139,76,4)",
  // red: "rgb(214,63,63)",
  // red2: "rgb(250, 224, 219)",
  blue2: "rgb(10, 75, 91)",
  blue3: "rgb(138,205,222)",

  orange: "rgb(255,158,27)",

  error: "rgb(153, 51, 0)",

  max1: "rgb(135, 66, 10)",
  max2: "rgb(218, 157, 113)",
  max3: "rgb(236, 159, 56)",
};

export default colors;
