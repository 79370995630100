import colors from "../colors";
import commonStyles from "./common";
import { fonts } from "../fonts";
import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("view", {
  styles: {
    default: {
      width: "100%",
      color: colors.cobalt2,
      boxSizing: "border-box",
    },

    borderSt: {
      border: "1px solid " + colors.white,
      // borderColor: colors.cobalt3,
      boxSizing: "border-box",
    },

    boxSt: {
      boxSizing: "border-box",
    },
    horizontalSt: {
      flexDirection: "row",
    },

    separateSt: {
      justifyContent: "space-between",
    },

    sparceSt: {
      justifyContent: "space-around",
    },

    sparcBwSt: {
      justifyContent: "space-between",
    },

    spaceEvenlySt: {
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    spaceAroundSt: {
      justifyContent: "space-around",
      alignItems: "center",
    },

    startSt: {
      justifyContent: "flex-start",
    },
    startAlignItemsSt: {
      alignItems: "flex-start",
    },
    endSt: {
      justifyContent: "flex-end",
    },
    endAlignItemsSt: {
      alignItems: "flex-end",
    },
    alignSelfEndSt: {
      alignSelf: "flex-end",
    },
    fullHeightSt: {
      flexBasis: "100%",
    },
    headerCenterBasisSt: {
      flexBasis: "75%",
    },
    headerEndBasisSt: {
      flexBasis: "24px",
    },

    fullwidthSt: {
      width: "100%",
    },

    containerSt: {
      height: "100%",
    },

    shrinkSt: {
      flexShrink: 1,
    },

    preguntaSt: {
      padding: "35px",
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "normal",
    },
    boxRuleListAlertSt: {
      borderRadius: 6,
      padding: "16px 14px",
      backgroundColor: colors.blue,
      margin: "0px 16px 22px 16px",
      boxSizing: "border-box",
      width: "calc(100% - 32px)",
    },

    boxLogoBancoSt: {
      // border: "1px solid #fff",
      width: "125px",
      height: "75px",
      borderRadius: "10px",
      backgroundColor: "rgba(154, 125, 55, 0.7)",
      margin: "5px",
    },
    bancoSelectedSt: {
      backgroundColor: colors.yellowBrown,
      opacity: 1,
    },

    itemsTargetSavingSt: {
      backgroundColor: "rgb(146, 6, 42, 0.9)",
      border: "1px solid " + colors.yellow,
      padding: "10px 15px",
      margin: "0.5rem",
      borderRadius: "40px",
      // width: "65%",
      // fontWeight: "bold",
      // fontSize: "16px",
      // textTransform: "uppercase",
    },

    likeBlueButtonSt: {
      backgroundColor: colors.greenblue,
      width: "70%",
      borderRadius: "40px",
      padding: "12px 0",
      marginBottom: "10px",
    },

    // yellowSt: {
    //   color: colors.yellow,
    // },

    itemMenuSettingsSt: {
      backgroundColor: "rgb(146, 6, 42, 0.9)",
      border: "1px solid " + colors.yellow,
      padding: "10px",
      margin: "10px",
      borderRadius: "40px",
      width: "70%",
      // width: "65%",
      // fontWeight: "bold",
      // fontSize: "16px",
      // textTransform: "uppercase",
    },

    itemsHistorySt: {
      width: "75%",
      backgroundColor: "rgb(146, 6, 42, 0.9)",
      border: "1px solid " + colors.yellow,
      padding: "10px 15px",
      margin: "5px",
      borderRadius: "40px",
    },

    notificationsSt: {
      alignItems: "flex-start",
      width: "80%",
      marginTop: "20px",
    },

    modalSt: {
      backgroundColor: "rgb(146, 6, 42, 0.3)",
      // border: "1px solid " + colors.red,
      padding: "40px 0",
    },

    centerSt: {
      justifyContent: "center",
    },

    bordeSt: {},

    generalLayoutSt: {
      width: "85%",
      marginTop: 32,
      alignItems: "flex-start",
    },
    generalLayoutMediumSt: {
      width: "91.5%",
    },
    generalLayoutLargeSt: {
      width: "93.6%",
      marginTop: 32,
      alignItems: "flex-start",
    },
    lineSeparatorSt: {
      height: 1,
      backgroundColor: colors.grey3,

      marginBottom: 15,
    },
    widthAutoSt: {
      width: "auto",
    },
    headerDimensionSt: {
      width: "93%",
      height: 64,
    },
    footerDimensionSt: {
      height: 53,
    },
    relativeSt: {
      position: "relative",
    },
    placeholderCircleSt: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      backgroundColor: colors.green4,
      marginRight: 12,
    },
    circleSt: {
      borderRadius: "50%",
      height: 58,
      width: 58,
    },
    green1St: {
      backgroundColor: colors.green1,
      transition: "all .4s",
    },
    grey4St: {
      backgroundColor: colors.grey4,
      transition: "all .4s",
    },
    whitetextSt: {
      color: colors.white,
    },
    whiteBgSt: {
      backgroundColor: colors.white,
    },
    bankiaBoldSt: {
      fontFamily: fonts.BankiaBold,
    },
    bankiaMediumSt: {
      fontFamily: fonts.BankiaMedium,
    },
    cardWithShadowSt: {
      borderRadius: 2,
      boxShadow: "0 0 7px rgba(0,0,0,0.2)",
    },
    cardWithShadowCobaltSt: {
      borderRadius: "6px",
      boxShadow: "0 3px 11px rgba(53, 38, 26, 0.05)",
    },
    /* 
    offset-x | offset-y | blur-radius | color 
    box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit;
    */

    lightShadowSt: {
      boxShadow: "0 0 7px rgba(0,0,0,0.2)",
    },
    greyBgSt: {
      backgroundColor: colors.grey11,
    },
    blueBgSt: {
      backgroundColor: colors.blue1,
      marginLeft: -3,
      zIndex: 9,
    },
    orangeBgSt: {
      backgroundColor: colors.orange1,
      marginLeft: -3,
      zIndex: 99,
    },
    yellowBgSt: {
      backgroundColor: colors.yellow1,
      zIndex: 999,
    },
    progressbarSt: {
      borderRadius: 30,
      height: 8,
      position: "relative",
    },

    progressBarCabeceraSt: {
      height: 4,
      position: "relative",
      background: "#d7d4d1",
    },

    progressBarCabeceraActivoSt: {
      height: 4,
      position: "absolute",
      top: 0,
      left: 0,
      backgroundImage:
        "linear-gradient(262deg, #b9c800, #b9c800, #64a000, #66a400)",
    },

    iconMetaSt: {
      height: 58,
      width: 58,
      borderRadius: "50%",
      marginBottom: 46,
    },
    circleDivSt: {
      height: 40,
      width: 40,
      borderRadius: "50%",
    },

    puntoDivSt: {
      height: 8,
      width: 8,
      borderRadius: "50%",
      background: colors.green6,
    },

    iconSt: {
      height: 56,
      width: 56,
      borderRadius: "50%",
      backgroundColor: colors.grey3,
      marginRight: 16,
    },

    flexGrowSt: {
      flexGrow: 1,
    },

    ...commonStyles.styles,
  },
});
