// Aqui se establecen las definiciones la fuente que usan
export const fonts = {
  SourceSansProBlack: 'SourceSansPro-Black',
  SourceSansProBold: 'SourceSansPro-Bold',
  SourceSansProExtraLight: 'SourceSansPro-ExtraLight',
  SourceSansProItalic: 'SourceSansPro-Italic',
  SourceSansProLight: 'SourceSansPro-Light',
  SourceSansProRegular: 'SourceSansPro-Regular',
  SourceSansProSemiBold: 'SourceSansPro-SemiBold',

  BankiaBold: 'Bankia-Bold',
  BankiaBoldItalic: 'Bankia-Bold-Italic',

  BankiaHeavy: 'Bankia-Heavy',
  BankiaHeavyItalic: 'Bankia-Heavy-Italic',

  BankiaItalic: 'Bankia-Italic',

  BankiaLight: 'Bankia-Light',

  BankiaMedium: 'Bankia-Medium',
  BankiaMediumItalic: 'Bankia-Medium-Italic',

  BankiaRegular: 'Bankia-Regular',
};

// Aqui se genera dinamicamente el objeto de fuentes a usar en los componentes
// export const fonts = Object.keys(fontsDefinition).reduce(
//   (acc, act) => {
//     if (!(act in acc)) {
//       throw new Error('No se permite "' + act + '" como definicion de fuente');
//     }
//     return {
//       ...acc,
//       [act]: fontsDefinition[act].name,
//     };
//   },
//   {
//     BankiaBold: undefined,
//     BankiaHeavy: undefined,
//     BankiaItalic: undefined,
//     BankiaLight: undefined,
//     BankiaMedium: undefined,
//     BankiaRegular: undefined,
//   }
// );
