import React from "react";
import { View, Image, Text } from "coinscrap-webapp-core";

import mapPin from "assets/images/map-pin.svg";
import gmaps from "assets/images/gmaps.png";

const GoogleMaps = () => {
  return (
    <View style={{ maxWidth: 385, height: "auto" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "0 32px",
        }}
      >
        <Image
          src={mapPin}
          style={{ height: 20, width: "auto", marginRight: 4 }}
        />
        <Text
          style={{
            color: "#979797",
            fontSize: 12,
            fontWeight: 700,
            textShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          }}
        >
          Avenida de Madrid, Vigo
        </Text>
      </View>
      <Image
        src={gmaps}
        style={{
          width: "100%",
          height: "auto",
          borderRadius: 10,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          marginTop: 5,
        }}
      />
    </View>
  );
};

export default GoogleMaps;
