import React from 'react';
import { Text, Button, View } from 'coinscrap-webapp-core';
import ModalContainer from './ModalContainer';
import CreditCard from './CreditCard';

const LostModal = ({ close, cardData }) => {
  return (
    <ModalContainer title={'Signaler la carte perdue ou volée'} close={close}>
      <CreditCard cardData={cardData} style={{ marginTop: 15 }} />
      <View style={{ padding: '0 40px 20px 40px' }}>
        <Text
          style={{
            fontSize: 15,
            fontWeight: 500,
            color: '#979797',
            marginTop: 45,
            textAlign: 'center',
          }}
        >
          La carte sera signalée comme perdue et désactivée.
        </Text>
        <Button style={{ border: 'none', height: 48, maxWidth: 290, marginTop: 20, width: '90%' }}>
          Signaler
        </Button>
      </View>
    </ModalContainer>
  );
};

export default LostModal;
