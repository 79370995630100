import React, { useState, useContext } from 'react';
import { UiWrapper, View, Image, Button } from 'coinscrap-webapp-core';

import Header from 'common/Header';
import Footer from 'common/Footer';
import CreditCard from './components/CreditCard';
import ButtonList from './components/ButtonList';
import OperationList from './components/OperationList';
import InfoMenu from './components/InfoMenu';
import BlockMenu from './components/BlockMenu';
import PinModal from './components/PinModal';
import LimitsModal from './components/LimitsModal';
import BlockModal from './components/BlockModal';
import LostModal from './components/LostModal';
import SettingsModal from './components/SettingsModal';

import colors from 'config/colors';

import hamburger from 'assets/images/hamburger.png';
import chat from 'assets/images/chat.png';
import reloadIcon from 'assets/images/reload.svg';

import { CardOperationsContext } from 'contexts/cardOperationsContext';
import GeneralSecurityModal from './components/GeneralSecurityModal';

const CardOperations = () => {
  const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
  const [isBlockMenuOpen, setIsBlockMenuOpen] = useState(false);

  const { cardOperations, reloadContext } = useContext(CardOperationsContext);
  const { useHeader, useFooter, openModal, useBackground } = UiWrapper.use();

  useBackground('#ffffff');

  useHeader(
    <>
      <Header
        key="Card"
        leftIcon={<Image style={{ height: 40, width: 40 }} src={hamburger} onClick={() => {}} />}
        rightIcon={
          <Image
            onClick={() => {}}
            style={{ height: 30, width: 30, padding: '0 5px' }}
            src={chat}
          />
        }
        title="Cartes"
        textCenterSt
      />
    </>
  );

  useFooter(<Footer key="Card" style={{}} />);

  const handleInfoButtonClick = () => {
    setIsInfoMenuOpen(true);
  };

  const handleBlockButtonClick = () => {
    setIsBlockMenuOpen(true);
  };

  const handleLimitsButtonClick = () => {
    openModal((close) => (
      <LimitsModal
        close={close}
        cardData={cardOperations}
        limits={{
          weekly: cardOperations?.cards?.[0].weeklyLimit,
          monthly: cardOperations?.cards?.[0].monthlyLimit,
          usedMonthly: cardOperations?.cards?.[0].monthlyUsedAllowance,
        }}
      />
    ));
  };

  const handleSettingsButtonClick = () => {
    openModal((close) => (
      <SettingsModal
        close={close}
        cardData={cardOperations}
        blocks={{
          online: cardOperations?.cards?.[0].internetBlocked,
          foreign: cardOperations?.cards?.[0].foreignBlocked,
        }}
      />
    ));
  };

  const handlePinClick = () => {
    setIsInfoMenuOpen(false);
    setTimeout(() => openModal((close) => <PinModal cardData={cardOperations} close={close} />), 0);
  };

  const handleBlockClick = () => {
    setIsBlockMenuOpen(false);
    openModal((close) => <BlockModal cardData={cardOperations} close={close} />);
  };

  const handleLostClick = () => {
    setIsBlockMenuOpen(false);
    openModal((close) => <LostModal cardData={cardOperations} close={close} />);
  };

  const handleSecurityBlockModal = () => {
    openModal((close) => (
      <GeneralSecurityModal title="Code de sécurité" action={handlePinClick} close={close} />
    ));
  };

  return (
    <View style={{ maxWidth: 840 }}>
      <View
        style={{
          position: 'absolute',
          background: `linear-gradient(133.92deg, ${colors.main} -3.21%, rgba(10, 45, 130, 0.19) 114.86%)`,
          top: 0,
          width: '100%',
          height: 169,
          zIndex: -1,
          clipPath: 'polygon(0 0, 100% 0, 100% 65%, 60% 100%, 0 57%)',
        }}
      />

      <CreditCard
        cardData={cardOperations}
        style={{
          marginTop: 15,
          opacity: cardOperations?.cards?.[0].blocked ? 0.8 : 1,
          backgroundColor: cardOperations?.cards?.[0].blocked ? 'gray' : colors.main,
        }}
      />
      {/* Esto vendría a ser el indicador del carrusel de tarjetas, que en principio iría implementado en un CardList o algo así */}
      <View
        style={{
          height: 6,
          width: 6,
          backgroundColor: colors.main,
          borderRadius: '50%',
          marginTop: 15,
        }}
      />
      <View style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: 50,
            right: 30,
            height: 20,
            width: 20,
            color: '#ffff',
            padding: 1,
            backgroundColor: 'transparent',
            border: 'none',
          }}
          onClick={async () => await reloadContext()}
        >
          <Image
            style={{
              height: '100%',
              width: '100%',
              color: '#ffff',
              filter:
                'invert(9%) sepia(70%) saturate(6922%) hue-rotate(227deg) brightness(74%) contrast(92%)',
            }}
            src={reloadIcon}
          />
        </Button>
      </View>
      <ButtonList
        onInfoClick={handleInfoButtonClick}
        onLimitsClick={handleLimitsButtonClick}
        onBlockClick={handleBlockButtonClick}
        onSettingsClick={handleSettingsButtonClick}
      />
      <OperationList data={cardOperations?.operations.slice(0, 9)} />
      {isInfoMenuOpen && (
        <>
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              backdropFilter: 'blur(3px)',
              backgroundColor: '#393939',
              zIndex: 6,
              opacity: '20%',
            }}
            onClick={() => setIsInfoMenuOpen(false)}
          />
          <InfoMenu
            onPinClick={() => {
              handleSecurityBlockModal();
              setIsInfoMenuOpen(false);
            }}
          />
        </>
      )}
      {isBlockMenuOpen && (
        <>
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              backdropFilter: 'blur(3px)',
              backgroundColor: '#393939',
              zIndex: 6,
              opacity: '80%',
            }}
            onClick={() => setIsBlockMenuOpen(false)}
          />
          <BlockMenu onBlockClick={handleBlockClick} onLostClick={handleLostClick} />
        </>
      )}
    </View>
  );
};

export default CardOperations;
