import React, { useContext, useState } from 'react';
import { UiWrapper, Image, NavigationWrapper, View } from 'coinscrap-webapp-core';

import Header from 'common/Header';

import arrowLeft from 'assets/images/arrow-square-left.svg';
import card from 'assets/images/card.svg';

import OperationSummary from './components/OperationSummary';
import GoogleMaps from './components/GoogleMaps';

import OperationData from './components/OperationData';
import routes from 'config/routes';
import { CardOperationsContext } from 'contexts/cardOperationsContext';
import { Page } from 'coinscrap-webapp-core';
import { useEffect } from 'react';

const OperationDetails = () => {
  const context = useContext(CardOperationsContext);
  const { cardOperations } = context;
  const { useHeader } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { params } = Page.use();
  const operations = cardOperations?.operations;
  const [operationData, setOperationData] = useState();

  useHeader(
    <Header
      key="Operation"
      leftIcon={
        <Image
          style={{ height: 33, width: 33, padding: '0 15px' }}
          src={arrowLeft}
          onClick={() => navigateTo(routes.card)}
        />
      }
      textCenterSt
    />
  );

  useEffect(() => {
    const operationData = operations?.filter((e) => e.orderId + '' === params.id);
    operationData && setOperationData(operationData[0]);
  }, [operations, params.id]);

  const mockData = {
    name: 'Paiement',
    icon: card,
    date: operationData?.date,
    amount: operationData?.amount,
    currency: '€',
    status: operationData?.status,
    carte: 'VISA **8603',
    statement: 'Télécharger',
    category: 'Shopping',
    note: 'Ajouter une note',
  };

  return (
    <View style={{ maxWidth: 840 }}>
      <OperationSummary key={mockData.name + mockData.date} data={mockData} />
      <GoogleMaps />
      <OperationData data={mockData} />
    </View>
  );
};

export default OperationDetails;
