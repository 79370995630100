export const mapCardOperations = (user, cards, operations) => {
  console.log('mapping user', { user });
  console.log('mapping cards', { cards });
  console.log('mapping operations', { operations });

  const res = {
    user: {
      name: user?.Profile.FirstName,
      lastName: user?.Profile.LastName,
      id: user?.AppUserId,
      reel: user?.Amount,
    },
    cards: cards.length
      ? cards?.map((card) => ({
          id: card.AppCardId,
          number: card.Hint,
          expiration: card.ExpiryDate,
          balance: card.CardLimits.MonthlyAllowance - card.CardLimits.MonthlyUsedAllowance,
          blocked: card.Blocked,
          foreignBlocked: card.ForeignPaymentBlocked,
          internetBlocked: card.InternetPaymentBlocked,
          weeklyLimit: card.CardLimits.ATMWeeklyAllowance,
          monthlyLimit: card.CardLimits.MonthlyAllowance,
          monthlyUsedAllowance: card.CardLimits.MonthlyUsedAllowance,
        }))
      : null,
    operations: operations
      ?.map((operation) => ({
        amount: operation.Amount,
        date: operation.OperationDate,
        id: operation.Id,
        orderId: operation.OrderId,
        status: operation.Status,
        RefundReference: operation.RefundReference
      }))
      .sort((a, b) => new Date(b.date) - new Date(a.date)),
  };

  return res;
};
