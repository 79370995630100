import { BackendWrapper } from 'coinscrap-webapp-core';
import { mapCardOperations } from 'mappers/CardOperations.Mapper';

//TODO control de errores con try/catch
//GET
const headers = {
  'Content-Type': 'application/json',
  // Authorization: `Bearer ${process.env.REACT_APP_BEARER_XPOLLENS}`,
  Authorization:
    'Bearer:eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc4Q0QwNDc2MDM2RUQxMDAwMEQzNjRFNEJCQzQ2RjE2NzFFNkIzNDJSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6ImVNMEVkZ051MFFBQTAyVGt1OFJ2Rm5IbXMwSSJ9.eyJuYmYiOjE2MjI1MzQ0NTAsImV4cCI6MTYyMjUzODA1MCwiaXNzIjoiaHR0cHM6Ly9zYi1jb25uZWN0Lnhwb2xsZW5zLmNvbSIsImF1ZCI6IkFQSS5HYXRld2F5IiwiY2xpZW50X2lkIjoiRGVtbyIsInBhcnRuZXJfY29kZSI6Inhwby1kZW1vLXBhcnRuZXIiLCJqdGkiOiI1MjdBQjlFNzI5NjAxOEFBRkVBRjY5NTZEMzA2M0QzNyIsImlhdCI6MTYyMjUzNDQ1MCwic2NvcGUiOlsicGFydG5lciJdfQ.DI3jNIUXRodDgYWZXJShghF9k2TNHYM6--2uSKwAHdbf9O53qo5NEbnakOGq1TbVLBdkG9qc15rw4DpRMe0-EmTsjBDOjBiA_CGjBbi7cuhj2qYADhVaFmy-hpfi_Hroj-vtwd3VmfIC-eSEimd1J_7oxu1gnatDjPE3P9sS9mlw8mto9CngeusoDCNDuLnc7u2ffK-7JCOBFR7WIFx5rsY8ALNj0xzVxNFaI1h_IVQPALXtjmGTrIYxECiuZ8aLuu_R1R9NOl2IuFMfKMeexbMCRZSrl5SQo4Tvhnd0_myMwHISqA07wgSTbLhgg0LjPFf1lG1_UdnXZUR4K_1u6A',
};

// https://api.macifdemo-dev.coinscrap.com/xpollens/connect/ -> ﻿https://sb-connect.xpollens.com/connect/
// https://api.macifdemo-dev.coinscrap.com/xpollens/api/ -> ﻿﻿https://sb-api.xpollens.com/api/

const proxy = '';
const URL_BASE = 'https://api.macifdemo-dev.coinscrap.com/xpollens/api/V1.1';
// const proxy = 'https://thingproxy.freeboard.io/fetch/';
// const URL_BASE = 'https://sb-api.xpollens.com/api/V1.1';

const getToken = async () => {
  try {
    let response = await fetch('https://api.macifdemo-dev.coinscrap.com/xpollens/connect/token', {
      // let response = await fetch(`${proxy}https://sb-connect.xpollens.com/connect/token`, {
      method: 'POST',
      body: 'grant_type=client_credentials&client_id=Demo&client_secret=Demo',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    response = await response.json();
    // console.log('>>>>>>>>>>>>>>>>>>>>>TOKEN:', response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getHeaders = async () => {
  const data = await getToken();
  return {
    headers: {
      Authorization: data.token_type + ' ' + data.access_token,
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json',
    },
  };
};

export const getUser = async (userId) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const response = await coreFetch(
    'GET',
    `${proxy}${URL_BASE}/users/${userId}`,
    await getHeaders(),
  );
  // console.log('******************getUser:', response);
  return response.data;
};



export const getUserCards = async (userId) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const response = await coreFetch(
    'GET',
    `${proxy}${URL_BASE}/users/${userId}/cards/issued/`,
    await getHeaders(),
  );
  return response.data;
};

export const getCard = async (cardId) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const response = await coreFetch(
    'GET',
    `${proxy}${URL_BASE}/users/DEMO_0001/cards/issued/${cardId}`,
    await getHeaders(),
  );
  return response.data;
};

export const getOperations = async (userId) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const response = await coreFetch(
    'GET',
    `${proxy}${URL_BASE}/users/${userId}/cardoperations`,
    await getHeaders(),
  );
  // console.log(response.data);
  return response.data;
};

export const getCardOperations = async (userId) => {
  const userData = await getUser(userId);
  console.log(userData)
  const operations = await getOperations(userId);

  console.log('>>get Cards for userId: '+userId)
  let CBCards = await getUserCards(userId)
  console.log('CBCards -> ', CBCards)
 

  const cardsData = await Promise.all(
    CBCards?.map((card) => getCard(card.AppCardId)) || [],
  );
  console.log(cardsData)

  return mapCardOperations(userData, cardsData, operations);
};

export const getOperationDetails = async (orderId, userId) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const response = await coreFetch(
    'GET',
    `${proxy}${URL_BASE}/users/${userId}/cardoperations/${orderId}`,
    { headers },
  );
  // console.log(response.data);
  return response.data;
};

//POST
export const postBlocks = async (userId, cardId, blocked, foreign, online) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const body = {
    Blocked: blocked,
    ForeignPaymentBlocked: foreign,
    InternetPaymentBlocked: online,
  };

  const response = await coreFetch(
    'PUT',
    `${proxy}${URL_BASE}/users/${userId}/cards/${cardId}`,
    await getHeaders(),
    { bodyParams: body },
  );
  // console.log(response.data);
  return response.data;
};

export const postLimits = async (userId, cardId, weekly, monthly) => {
  const coreFetch = BackendWrapper.getCoreFetch();
  const body = {
    CardLimits: {
      ATMWeeklyAllowance: weekly,
      MonthlyAllowance: monthly,
    },
  };

  const response = await coreFetch(
    'PUT',
    `${proxy}${URL_BASE}/users/${userId}/cards/${cardId}`,
    await getHeaders(),
    { bodyParams: body },
  );
  return response.data;
};
