import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";

UiWrapper.defineComponent("inputSlider", {
  styles: {
    default: {
      containerComponent: {
        width: "calc(100% - 15px)",
      },
      railComponent: {
        height: 20,

        backgroundColor: colors.grey3,

        borderRadius: 10,
        overflow: "hidden",
        paddingRight: 15,
      },
      trackComponent: {
        height: 20,
        backgroundColor: colors.green1,
        background: `linear-gradient(to left,${colors.green4}, ${colors.green4}, ${colors.green2}, ${colors.green3})`,

        borderRadius: 10,
        overflow: "hidden",
        paddingRight: 12,
      },
      thumbComponent: {
        width: 12,
        height: 12,
        marginTop: 4,
        color: "#ffffff",
        backgroundColor: "#ffffff",
      },
      markComponent: {
        overflow: "hidden",
        height: 20,
      },
    },
  },
});
