import React from "react";
import OperationItem from "./OperationItem";
import { View, Text, Image, NavigationWrapper } from "coinscrap-webapp-core";
import colors from "config/colors";
import arrow from "assets/images/arrow-circle-right.svg";
import routes from "config/routes";

const OperationList = React.memo(({ data }) => {
  const { navigateTo } = NavigationWrapper.use();

  return (
    <View
      style={{
        width: "95%",
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 17,
        padding: "10px 19px",
        flexShrink: 1,
        overflow: "auto",
        justifyContent: "flex-start",
        marginBottom: 20,
      }}
    >
      <Text style={{ fontSize: 18, fontWeight: 700, color: colors.main }}>
        Operations
      </Text>
      {data?.map((item) => (
        <OperationItem
          key={item.name + item.date}
          data={item}
          onClick={() => {
            navigateTo(`${routes.operation}/${item.orderId}`);
          }}
          style={{ opacity: item.status > 2 ? "0.25" : "1" }}
        />
      ))}
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <View style={{ flex: 1 }} />
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            width: 130,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: 600,
              textTransform: "uppercase",
              color: colors.main,
            }}
          >
            Voir Plus
          </Text>
          <Image src={arrow} />
        </View>
      </View>
    </View>
  );
});

export default OperationList;
