import colors from "../colors";
import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("inputSelect", {
  styles: {
    default: {
      containerComponent: {
        width: "80%",
      },
      selectContainerComponent: {
        borderRadius: "30px",
        backgroundColor: "rgb(166, 121, 129, 0.5)",
        color: colors.white,
        border: "1px solid " + colors.orange,
      },
      labelComponent: {
        fontSize: 14,
        fontWeight: 600,
      },
      menuContainerComponent: {
        backgroundColor: "rgb(255,255,255)",
        borderRadius: "25px",
      },
    },
  },
});
