import colors from "../colors";
import { fonts } from "../fonts";
import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("inputDate", {
  styles: {
    default: {
      containerComponent: {
        width: "100%",
        height: 53,
        display: "flex",
        justifyContent: "flex-end",
      },
      inputContainerComponent: {
        backgroundColor: colors.white,
        color: colors.cobalt2,

        borderBottom: "1px solid " + colors.grey3,

        padding: 0,
        paddingBottom: 10,
      },
      labelComponent: {
        fontSize: 14,
        fontWeight: 600,
      },
      toolbarComponent: {
        backgroundColor: "#b9c800",
      },
      daySelectedComponent: {
        backgroundColor: "#b9c800",
      },
      dayComponent: {
        color: colors.grey,
        fontFamily: fonts.SourceSansProRegular,
      },
      currentDayComponent: {
        color: "#b9c800",
      },
      actionTextComponent: {
        color: "#b9c800",
      },
      inputComponent: {
        padding: 0,
        fontSize: 18,
        lineheight: "24px",
        fontFamily: fonts.SourceSansProRegular,
        color: colors.cobalt2,
        // border: "1px solid " + colors.orange,
      },
      iconContainerComponent: {
        padding: 0,
        width: 35,
        height: 30,
      },
    },
  },
});
