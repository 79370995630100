import { UiWrapper } from "coinscrap-webapp-core";
import colors from "./../colors";

UiWrapper.defineComponent("inputSwitch", {
  styles: {
    default: {
      containerComponent: {
        border: "0px solid #f60",
        height: 31,
      },
      switchContainerComponent: {
        border: "0px solid #6f0",
        padding: 0,
        height: 31,
        width: 51,
        paddingBottom: 1,
      },
      switchComponent: {
        border: "0px solid #6f0",
        padding: 2,
        //margin: "8px 0 0 0",
      },
      trackComponent: {
        backgroundColor: "#78788029",
        opacity: 1,
        height: 31,
        borderRadius: 30,
        padding: 0,
      },
      thumbComponent: {
        height: 27,
        width: 27,
        padding: 0,
      },
      checkedMode: {
        containerComponent: {
          //border: "0px solid yellow",
          //padding: 0,
          // margin: "8px 0 0 0",
        },
        thumbComponent: {
          backgroundColor: "#ffffff",
        },
        trackComponent: {
          backgroundColor: "#E6325A",
        },
      },
    },
    smallCircleSt: {
      labelComponent: {},
      containerComponent: {
        padding: 0,
        margin: 0,
        width: 60,
        marginBottom: 8,
      },
      switchContainerComponent: {
        width: 100,
        position: "relative",
      },
      switchComponent: {
        // top: '-50%',
        left: 8,
        top: 3.5,
      },
      thumbComponent: {
        width: 18,
        height: 18,
        backgroundColor: colors.grey9,
        // transform: ' translateX(8px)',
      },
      trackComponent: {
        height: 24,

        marginTop: -3,
        // borderRadius: 26 / 2,
        borderRadius: 12,
        width: "48px",
        padding: 0,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grey7}`,
      },
      checkedMode: {
        labelComponent: {},
        containerComponent: {
          padding: 0,
          margin: 0,
          width: 60,
          marginBottom: 8,
        },
        switchContainerComponent: {
          width: 100,
          position: "relative",
        },
        switchComponent: {
          // top: '-50%',
          left: 10,
          top: 3.5,
        },
        thumbComponent: {
          width: 18,
          height: 18,
          backgroundColor: colors.green4,
          // transform: ' translateX(8px)',
        },
        trackComponent: {
          height: 24,

          marginTop: -3,
          // borderRadius: 26 / 2,
          borderRadius: 12,
          width: "48px",
          padding: 0,
          backgroundColor: colors.white,
          border: `1px solid ${colors.green1}`,
        },
      },
    },
  },
});
