import React, { Component } from "react";

import config from "config";

import OnBoarding from "views/OnBoarding";
import Login from "views/Login";
import Signup from "views/Signup";
import CardOperations from "views/CardOperations";
import OperationDetails from "views/OperationDetails";
import splash from "assets/images/splash.png";

import {
  ApplicationWrapper,
  NavigationWrapper,
  UiWrapper,
  BackendWrapper,
  Page,
  Module,
  /* SessionWrapper, */
} from "coinscrap-webapp-core";

import routes from "./config/routes";
import BasicLayout from "./common/BasicLayout";
import { UserProvider } from "contexts/userContext";
import { CardOperationsProvider } from "contexts/cardOperationsContext";

ApplicationWrapper.init({
  projectName: "macif",
  contextTheme: "light",
  splashBackground: splash,
});

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ApplicationWrapper>
          <BackendWrapper enableNativeBridge={true}>
            <UiWrapper config={config} backgroundImages={{}}>
              <NavigationWrapper>
                {/*<SessionWrapper
                  sessionCheck={(sessionData) => sessionData.userId}
                >*/}
                <UserProvider>
                  <CardOperationsProvider>
                    <BasicLayout>
                      <Page path="/" name={"/"} component={OnBoarding} />{" "}
                      <Page
                        path="/login"
                        name={routes.login}
                        component={Login}
                      />
                      <Page
                        path="/signup"
                        name={routes.signup}
                        component={Signup}
                      />
                      <Module path="/inApp">
                        <Page
                          path="/card"
                          name={routes.card}
                          component={CardOperations}
                        />
                        <Page
                          path="/operation/:id"
                          name={routes.operation}
                          component={OperationDetails}
                        />
                      </Module>
                    </BasicLayout>
                  </CardOperationsProvider>
                </UserProvider>
                {/* </SessionWrapper> */}
              </NavigationWrapper>
            </UiWrapper>
          </BackendWrapper>
        </ApplicationWrapper>
      </React.Fragment>
    );
  }
}
