import React, { useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from 'contexts/userContext';
import { getCardOperations } from 'api/xpollens';
import { UiWrapper } from 'coinscrap-webapp-core';

const initialData = { data: {}, setCardOperations: () => {} };

export const CardOperationsContext = React.createContext(initialData);

export const CardOperationsProvider = ({ children }) => {
  const { useLoading } = UiWrapper.use();
  const [cardOperations, setCardOperations] = useState();

  const { userId } = useContext(UserContext);

  const getData = useCallback(async () => {
    try {
      const res = await getCardOperations(userId);
      setCardOperations(res);
    } catch (e) {
      setCardOperations(null);
    }
  }, [userId]);

  const reloadContext = async () => {
    return new Promise((resolve, reject) => {
      getData().then(resolve).catch(reject);
    });
  };

  useEffect(() => {
    userId && getData();
  }, [userId, getData]);

  // Muestra un loading de aplicación mientras no se cargen estos datos
  // console.log('USE LOADING -> ', userId, cardOperations, userId && cardOperations === undefined);
  useLoading(userId && cardOperations === undefined);
  return (
    <CardOperationsContext.Provider value={{ cardOperations, setCardOperations, reloadContext }}>
      {children}
    </CardOperationsContext.Provider>
  );
};
