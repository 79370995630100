import React from 'react';
import { View, Image } from 'coinscrap-webapp-core';

import home from 'assets/images/house.svg';
import chart from 'assets/images/chart.svg';
import card from 'assets/images/card.svg';
import transfer from 'assets/images/transfer.svg';
import footerBg from 'assets/images/footer-bg.png';

const Footer = ({ style }) => {
  return (
    <View
      boxSt
      footerDimensionSt
      horizontalSt
      style={{ ...style, position: 'relative', maxWidth: 450 }}
    >
      <View
        style={{
          position: 'absolute',
          width: '100%',
          top: -46,
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          overflowY: 'hidden',
        }}
      >
        <Image src={footerBg} style={{ height: '100%', width: 414 }} />
      </View>
      <View
        style={{
          width: 60,
          height: 60,
          backgroundColor: 'transparent',
          zIndex: 5,
          top: -30,
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          borderRadius: '50%',
        }}
      />
      <View
        style={{
          padding: '0 30px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          zIndex: 4,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '30%',
            justifyContent: 'space-between',
          }}
        >
          <Image src={home} style={{ height: 18, width: 'auto' }} />
          <Image src={chart} style={{ height: 16, width: 'auto' }} />
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '30%',
            justifyContent: 'space-between',
          }}
        >
          <Image src={card} style={{ height: 16, width: 'auto' }} />
          <Image src={transfer} style={{ height: 16, width: 'auto' }} />
        </View>
      </View>
    </View>
  );
};

export default Footer;
