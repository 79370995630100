import componentsConfig from "./components";
import colors from "./colors";

export default {
  ...componentsConfig,
  fontFamily: "SourceSansPro-Regular",
  colors: {
    defaultFontColor: colors.cobalt2,
    main: colors.cobalt2,
    secondary: colors.black2,
  },
};
