import React from "react";
import { View, Text, Image } from "coinscrap-webapp-core";
import colors from "config/colors";

const OperationDataItem = ({ children, value, icon, last, color }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        padding: "25px 0",
        borderBottom: `${last ? "none" : "1px solid #D2D1D7"}`,
      }}
    >
      <Text
        style={{
          color: "#373940",
          fontSize: 14,
          fontWeight: 700,
          marginRight: 80,
          width: 100,
        }}
      >
        {children}
      </Text>
      <Image src={icon} style={{ width: 19, height: "auto" }} />
      <Text
        style={{
          // color: colors.main,
          fontSize: 12,
          fontWeight: 700,
          marginLeft: "auto",
          color: color ? color : colors.main,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

export default OperationDataItem;
