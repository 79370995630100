import { UiWrapper } from 'coinscrap-webapp-core';
import colors from '../colors';
import { fonts } from '../fonts';

UiWrapper.defineComponent('button', {
  styles: {
    default: {
      backgroundColor: colors.main,
      borderRadius: 12,
      fontSize: 18,
      maxWidth: 350,
      width: '100%',
      textComponent: {
        color: colors.white,
        fontSize: 15,
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    },

    whiteButtonSt: {
      background: colors.white,
      borderRadius: 13,
      borderColor: colors.white,
      padding: 0,
      margin: 0,
      maxWidth: 290,
      height: 48,
      width: '90%',
      textComponent: {
        color: '#f60',
      },
    },

    transparentButtonSt: {
      background: 'transparent',
      borderRadius: 13,
      borderWidth: 1,
      borderColor: colors.white,
      padding: 0,
      margin: 0,
      width: 290,
      height: 48,
      textComponent: {
        color: '#f60',
      },
    },

    // Pildora hueca con borde rojo
    pillSt: {
      borderColor: colors.red,
      borderStyle: 'solid',
      borderRadius: 20,
      borderWidth: 1,
      margin: 10,
      paddingLeft: 35,
      paddingRight: 35,
      backgroundColor: colors.white,
      fontSize: 18,
      maxWidth: 350,
      width: '100%',

      textComponent: {
        color: colors.red,
        marginBottom: 2,
        fontWeight: 100,
        fontSize: 16,
        fontFamily: fonts.regular,
      },
    },

    pillFillSt: {
      borderColor: colors.red,
      borderStyle: 'solid',
      borderRadius: 20,
      borderWidth: 1,
      margin: 10,
      paddingLeft: 35,
      paddingRight: 35,
      backgroundColor: colors.red,
      fontSize: 18,
      maxWidth: 350,
      width: '100%',

      textComponent: {
        color: colors.white,
        padding: 0,
        marginBottom: 2,
        fontWeight: 100,
        fontSize: 16,
        fontFamily: fonts.bold,
      },
    },

    // Para cuando se deshabilita
    disabledSt: {
      backgroundColor: 'white',
      borderColor: 'silver',

      textComponent: {
        color: colors.grey,
      },
    },

    squareSt: {
      borderColor: colors.greyLight2,
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderRadius: 5,
      borderWidth: 2,
      fontSize: 18,
      outline: 'none',
      height: 75,
      minWidth: 70,
      paddingLeft: 0,
      paddingRight: 0,

      textComponent: {
        color: colors.grey,
        // paddingTop: 10,
        // paddingBottom: 10,
        fontSize: 14,
        fontFamily: fonts.regular,
        // fontWeight: 'bold',
      },
    },

    squareFillSt: {
      backgroundColor: colors.green,
      borderColor: colors.green,
      borderStyle: 'solid',
      borderRadius: 5,
      borderWidth: 2,
      fontSize: 18,
      outline: 'none',
      height: 75,
      minWidth: 70,

      textComponent: {
        // color: colors.grey,
        // paddingTop: 10,
        // paddingBottom: 10,
        fontSize: 14,
        color: colors.white,
        fontFamily: fonts.regular,
        // fontWeight: 'bold',
      },
    },

    leftIconSt: {
      width: '100%',
      minWidth: 150,
      maxWidth: 200,
      margin: 1,
      padding: 3,
      borderColor: colors.greyLight2,
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderRadius: 5,
      borderWidth: 2,
      fontSize: 18,
      minHeight: 60,
      display: 'flex',
      flexDirecction: 'row',
      alignItems: 'center',
      justifyItems: 'center',
      justifyContent: 'space-between',

      iconComponent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 42,
        height: 45,
        width: 45,
        objectFit: 'contain',
      },

      textComponent: {
        color: colors.black,
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 22,
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
      },
    },
    // Botones cuadrados con Icono encima
    topIconSt: {
      width: '100%',
      maxWidth: 100,
      margin: 1,
      padding: 3,
      borderColor: colors.greyLight2,
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderRadius: 5,
      borderWidth: 2,
      fontSize: 18,
      minHeight: 75,
      iconComponent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 42,
      },

      textComponent: {
        color: colors.black,
        paddingBottom: 5,
        fontSize: 14,
      },
    },
    roundedBlueSt: {
      width: 'auto',
      minWidth: 200,
      border: 'none',
      backgroundColor: colors.greenblue,
      borderRadius: 20,
      paddingTop: 8,
      paddingBottom: 8,
      textComponent: {
        textTransform: 'uppercase',
        color: colors.white,
      },
    },

    roundedBlue2bSt: {
      width: 'auto',
      minWidth: 200,
      border: 'none',
      backgroundColor: colors.greenblue,
      borderRadius: 20,
      textComponent: {
        color: colors.white,
        fontSize: 16,
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        textTransform: 'none',
      },
    },

    roundedBlueDisabledSt: {
      width: 'auto',
      minWidth: 200,
      border: 'none',
      backgroundColor: colors.grey,
      borderRadius: 20,
      paddingTop: 8,
      paddingBottom: 8,
      textComponent: {
        textTransform: 'uppercase',
        color: colors.white,
      },
    },

    // roundedVioletSt: {
    //   width: "auto",
    //   minWidth: 200,
    //   border: "none",
    //   backgroundColor: colors.greenblue,
    //   borderRadius: 20,
    // },

    textSt: {
      width: 'auto',
      minWidth: 200,
      backgroundColor: 'transparent',
      border: 'none',
    },

    noTextTransformSt: {
      textComponent: {
        textTransform: 'none',
      },
    },

    lowerCaseSt: {
      textTransform: 'lowercase',
    },

    upperCaseSt: {
      textTransform: 'uppercase',
    },

    outlinedSt: {
      width: 'auto',
      minWidth: 150,
      border: '1px solid ' + colors.yellow,
      backgroundColor: 'transparent',
      borderRadius: 30,
      paddingTop: 15,
      paddingBottom: 15,
      textComponent: {
        textTransform: 'uppercase',
        color: colors.white,
        fontSize: '16px',
      },
      disabledMode: {
        border: '1px solid ' + colors.grey,
        textComponent: {
          color: colors.grey,
        },
      },
    },

    enhancedSt: {
      width: 'auto',
      minWidth: 150,
      border: '1px solid ' + colors.yellow,
      backgroundColor: colors.yellow,
      borderRadius: 30,
      paddingTop: 15,
      paddingBottom: 15,
      textComponent: {
        textTransform: 'uppercase',
        color: colors.white,
        fontSize: '16px',
      },
      disabledMode: {
        border: '1px solid ' + colors.grey,
        backgroundColor: colors.grey,
        textComponent: {
          color: colors.white,
        },
      },
    },

    fillAllFooterSt: {
      maxWidth: '100%',
      height: '100%',
      borderRadius: 0,
      padding: 0,
      margin: 0,
      border: 0,
      backgroundColor: colors.yellow,
      textComponent: {
        textTransform: 'none',
        fontSize: '20px',
      },
      disabledMode: {
        maxWidth: '100%',
        height: '100%',
        borderRadius: 0,
        padding: 0,
        margin: 0,
        border: 0,
        backgroundColor: colors.grey,
        textComponent: {
          textTransform: 'none',
          fontSize: '20px',
        },
      },
    },

    /*
    fillAllFooterDisabledSt: {
      maxWidth: "100%",
      height: "100%",
      borderRadius: 0,
      padding: 0,
      margin: 0,
      border: 0,
      backgroundColor: colors.grey,
      textComponent: {
        textTransform: "none",
        fontSize: "20px",
      },
    },*/

    h2BtnSt: {
      textComponent: {
        textTransform: 'none',
        fontSize: '18px',
      },
    },

    h3BtnSt: {
      textComponent: {
        textTransform: 'none',
        fontSize: '14px',
        fontFamily: fonts.regular,
        fontWeight: 'none',
      },
    },

    h4BtnSt: {
      textComponent: {
        textTransform: 'none',
        fontSize: '14px',
        fontFamily: fonts.regular,
        fontWeight: 'none',
      },
    },

    saveChangesSt: {
      borderRadius: 30,
      padding: '10px 30px',
      textComponent: {
        fontWeight: 'bold',
        // margin: "3px 50px",
        // color: colors.red,
      },
    },

    totalSt: {
      width: 'auto',
      minWidth: 150,
      border: '1px solid ' + colors.yellow,
      backgroundColor: colors.yellow,
      borderRadius: 30,
      paddingTop: 5,
      textComponent: {
        textTransform: 'uppercase',
        color: colors.white,
        fontSize: '16px',
      },
    },

    parcialSt: {
      width: 'auto',
      minWidth: 150,
      border: '1px solid ' + colors.yellow,
      backgroundColor: 'transparent',
      borderRadius: 30,
      paddingTop: 5,
      textComponent: {
        textTransform: 'uppercase',
        color: colors.white,
        fontSize: '16px',
      },
    },

    footerBtnBlueSt: {
      width: 'auto',
      minWidth: 200,
      border: 'none',
      backgroundColor: colors.greenblue,
      borderRadius: 40,
      textComponent: {
        margin: '0px 80px',
        color: colors.white,
        fontSize: 18,
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        textTransform: 'none',
      },
    },

    btnCloseSessionBlueSt: {
      // width: "auto",
      // minWidth: 200,
      border: 'none',
      backgroundColor: colors.greenblue,
      borderRadius: 40,
      textComponent: {
        color: colors.white,
        fontSize: 18,
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        textTransform: 'none',
      },
    },

    footerButtonSt: {
      padding: 18,
      height: 'auto',

      background: `linear-gradient(to left,${colors.green4}, ${colors.green4}, ${colors.green2}, ${colors.green3})`,
      boxShadow: '0 15px 10px -12px rgba(0,0,0,0.2)',
    },
  },
});
