import React from "react";
import { View, Text } from "coinscrap-webapp-core";
import { Slider } from "coinscrap-webapp-core";
import { slider_data } from "data/onboardingData";

const Slide = ({ text }) => {
  return (
    <Slider
      showDots
      style={{
        dotsContainerComponent: { position: "relative", marginTop: 20 },
      }}
    >
      {slider_data.map((item) => {
        return (
          <View>
            <Text font20St font700wSt centerSt style={{ marginTop: 10 }}>
              {item.titulo}
            </Text>
            <Text font16St centerSt style={{ margin: "16px 40px 4px 40px" }}>
              {item.subtitulo}
            </Text>
          </View>
        );
      })}
    </Slider>
  );
};

export default Slide;
